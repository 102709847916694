import React, { useState, useEffect, useCallback } from 'react';
import { fetchWithJwt } from "components/fetchWithJwt";
import { useNavigate } from 'react-router-dom';

const Store = () => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
  const [storeData, setStoreData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchData = useCallback(async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/device/show`);
      if (!response.ok) throw new Error(`Failed to fetch data: ${response.status} - ${response.statusText}`);
      const data = await response.json();
      setStoreData(data.data);
      setFilteredData(data.data);
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  }, [API_DOMAIN]);

  const searchHandle = (value) => {
    setSearchQuery(value);
    const filtered = storeData.filter((sensor) =>
      sensor.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const navigate = useNavigate();
  
  const goToDevice = (deviceId) => {
    navigate(`/device/${deviceId}`);
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 60000); // Fetch setiap 1 menit
    return () => clearInterval(interval);
  }, [fetchData]);

  return (
    <div className="">
      <div className="flex flex-col gap-4">
        <div>
          <input 
            value={searchQuery} 
            onChange={(e) => searchHandle(e.target.value)} 
            type="text" 
            placeholder="Cari..." 
            className="p-2 border border-gray-300 rounded-md w-full" 
          />
        </div>
        {filteredData.map((sensor) => (
          <div key={sensor.id} className='flex flex-col gap-4 shadow-md p-4'>
            <div className="text-xl font-bold">{sensor.name}</div>
            <div className="flex flex-wrap gap-1">
              {sensor.alat.map((alat) => (
                <StatusColor 
                  key={alat.device_id} 
                  status={alat.status} 
                  online={alat.online} 
                  onClick={() => { goToDevice(alat.device_id) }}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const StatusColor = ({ status, online, onClick }) => {
  const temperatureData = status.find((stat) => stat.code === 'va_temperature');
  const temp = temperatureData ? temperatureData.value / 10 : null;

  const tempClass = temp > 27 ? 'bg-red-500' : temp < 23 ? 'bg-blue-500' : 'bg-green-500';

  return (
    <div 
      onClick={onClick} 
      className={`w-1/6 ${online === 0 ? 'bg-gray-500' : tempClass} rounded-md text-white text-center p-2 cursor-pointer`}
    >
      <div className='text-sm'>{online === 0 ? 'OFF' : (temp != null ? `${temp} °C` : '-')}</div>
    </div>
  );
};

export default Store;
