import { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "views/login";
import Password from "views/password";
import AdminLayout from "layouts/admin";
import Mobile from "layouts/Mobile";
import Device from "views/deviceShow/detail";
import User from "views/user";

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (!userData) {
      navigate("/auth/login");
    }
  }, [navigate]);

  return (
    <>
      <Routes>
        <Route path="auth/login" element={<Login />} />
        <Route path="password" element={<Password />} />
        <Route path="mobile" element={<Mobile />} />
        <Route path="profil" element={<User />} />
        <Route path="device/:id" element={<Device />} />
        <Route path="/" element={<Navigate to="/mobile" replace />} />
      </Routes>
    </>
  );
};

export default App;
