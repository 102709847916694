import React from "react";
import Card from "components/card";
import { MdOutlinePerson } from "react-icons/md";

const User = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));

  function ucwords(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  return (
    
    <Card extra={"mt-5 items-center"}>
      <div className="flex mt-5 h-[80px] w-[80px] text-5xl items-center rounded-full border-[4px] text-navy-700 justify-center border-navy-700 dark:text-white dark:border-white">
        <MdOutlinePerson/>
      </div>
      <div className="flex mt-5 mb-5 flex-col items-center">
        <p className="text-xl font-bold text-navy-700 dark:text-white">{ucwords(userData.name)}</p>
      </div>
    </Card>
  );
};

export default User;
